<template>
  <b-row>
    <b-col cols="12">
      <form-wizard
        color="#26a5ea"
        :title="null"
        :subtitle="null"
        class="mb-3"
        shape="square"
        step-size="xs"
        back-button-icon="EditIcon"
        next-button-text="Guardar y seguir"
        back-button-text="Anterior"
        finish-button-text="Guardar Matrícula"
        @on-complete="formSubmitted"
      >

        <!-- Info Apoderado -->
        <tab-content
          title="Apoderado *"
          :before-change="validaApoderado"
        >
          <b-row>
            <!-- TITULO: Info del apoderado -->
            <b-col
              cols="12"
              class="mb-2 text-center"
            >
              <h5 class="mb-0">
                Apoderado
              </h5>
              <small class="text-muted">
                Ingresa la información del apoderado.
              </small>
            </b-col>
            <!-- SUB TITULO: Información personal -->
            <b-col
              cols="12"
              md="2"
              class="mb-2 mt-2"
            >
              <b-card-sub-title>
                Información personal
              </b-card-sub-title>
            </b-col>
            <b-col
              cols="12"
              md="10"
              class="mb-1 mt-2"
            >
              <b-card-sub-title >
                <hr class="mt-75">
              </b-card-sub-title>
            </b-col>
            <b-col
              cols="6"
            >
              <!-- INFO PERSONAL -->
              <b-row>

                <!-- RUT -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Rut *"
                    label-for="rut"
                  >
                    <b-form-input
                      id="rut"
                      v-model="apoderado.rut"
                      placeholder="11.111.111-1"
                      :state="v$.apoderado.rut.$error === true
                      ? false
                      : null"
                      @blur.native="v$.apoderado.rut.$touch"
                      @keyup="apoderado.rut = formatRut(apoderado.rut)"
                    />
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.apoderado.rut.$error"
                      id="rutInfo"
                      class="pb-0"
                    >
                      <p
                        v-for="error of v$.apoderado.rut.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- NOMBRE -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Primer nombre *"
                    label-for="nombre"
                  >
                    <b-form-input
                      id="nombre"
                      v-model="apoderado.nombre"
                      placeholder="Isidora"
                      :state="v$.apoderado.nombre.$error === true
                      ? false
                      : null"
                      @blur.native="v$.apoderado.nombre.$touch"
                    />
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.apoderado.nombre.$error"
                      id="nombreInfo"
                    >
                      <p
                        v-for="error of v$.apoderado.nombre.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- SEGUNDO NOMBRE -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Segundo nombre"
                    label-for="segundo_nombre"
                  >
                    <b-form-input
                      id="segundo_nombre"
                      v-model="apoderado.segundo_nombre"
                      placeholder="Paz"
                      :state="v$.apoderado.segundo_nombre.$error === true
                      ? false
                      : null"
                      @blur.native="v$.apoderado.segundo_nombre.$touch"
                    />
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.apoderado.segundo_nombre.$error"
                      id="segundo_nombreInfo"
                    >
                      <p
                        v-for="error of v$.apoderado.segundo_nombre.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- PRIMER APELLIDO -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Primer apellido *"
                    label-for="primer_apellido"
                  >
                    <b-form-input
                      id="primer_apellido"
                      v-model="apoderado.primer_apellido"
                      placeholder="Álvarez"
                      :state="v$.apoderado.primer_apellido.$error === true
                      ? false
                      : null"
                      @blur.native="v$.apoderado.primer_apellido.$touch"
                    />
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.apoderado.primer_apellido.$error"
                      id="primer_apellidoInfo"
                    >
                      <p
                        v-for="error of v$.apoderado.primer_apellido.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- SEGUNDO APELLIDO -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Segundo apellido *"
                    label-for="segundo_apellido"
                  >
                    <b-form-input
                      id="segundo_apellido"
                      v-model="apoderado.segundo_apellido"
                      placeholder="Rivera"
                      :state="v$.apoderado.segundo_apellido.$error === true
                      ? false
                      : null"
                      @blur.native="v$.apoderado.segundo_apellido.$touch"
                    />
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.apoderado.segundo_apellido.$error"
                      id="segundo_apellidoInfo"
                    >
                      <p
                        v-for="error of v$.apoderado.segundo_apellido.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- FECHA DE NACIMIENTO -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Fecha de nacimiento *"
                    label-for="fecha_nacimiento"
                  >
                    <flat-pickr
                      v-model="apoderado.fecha_nacimiento"
                      class="form-control"
                      :config="config.locale"
                      placeholder="Selecciona una fecha"
                      name="date"
                      :class="v$.apoderado.fecha_nacimiento.$error === true
                        ? 'form-control border-danger rounded'
                        : 'form-control'"
                    />
                    <!-- Validaciones -->
                    <div
                      v-if="v$.apoderado.fecha_nacimiento.$error"
                      id="asistentesInfo"
                      class="text-danger text-left"
                      style="font-size: 0.857rem;"
                    >
                      <p v-for="error of v$.apoderado.fecha_nacimiento.$errors" :key="error.$uid">
                        {{ error.$message }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- GENERO -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Genero *"
                    label-for="genero"
                  >
                    <v-select
                      v-model="apoderado.genero"
                      placeholder="Selecciona un genero"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :reduce="option => option.genero"
                      :options="optionsGeneros"
                      :class="v$.apoderado.genero.$error === true
                        ? 'border-danger rounded'
                        : ''"
                    />
                      <!-- :disabled="optionsGeneros.length === 0" -->
                    <div
                      v-if="v$.apoderado.genero.$error"
                      id="asistentesInfo"
                      class="text-danger text-left"
                      style="font-size: 0.857rem;"
                    >
                      <p v-for="error of v$.apoderado.genero.$errors" :key="error.$uid">
                        {{ error.$message }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>

              </b-row>
            </b-col>
            <b-col
              cols="6"
            >
              <b-row>

                <!-- CORREO -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Correo *"
                    label-for="correo"
                  >
                    <b-input-group
                      :class="v$.apoderado.correo.$error === false
                        ? ''
                        : 'is-invalid'"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MailIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="correo"
                        v-model="apoderado.correo"
                        placeholder="paz.alv@gmail.com"
                        :class="v$.apoderado.correo.$error === false
                          ? ''
                          : 'border-danger rounded-right'"
                        @blur.native="v$.apoderado.correo.$touch"
                        :state="v$.apoderado.correo.$error === true
                        ? false
                        : null"
                      />
                    </b-input-group>
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.apoderado.correo.$error"
                      id="correoInfo"
                    >
                      <p
                        v-for="error of v$.apoderado.correo.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- CELULAR -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Celular *"
                    label-for="celular"
                  >
                    <b-input-group
                      :class="v$.apoderado.celular.$error === false ? '' : 'is-invalid'"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="SmartphoneIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="celular"
                        v-model="apoderado.celular"
                        placeholder="56978717595"
                        :state="v$.apoderado.celular.$error === true
                        ? false
                        : null"
                        @keyup='apoderado.celular = formatSoloNumerosMaxLenght(apoderado.celular, 11)'
                        @blur.native="v$.apoderado.celular.$touch"
                      />
                    </b-input-group>
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.apoderado.celular.$error"
                      id="celularInfo"
                    >
                      <p
                        v-for="error of v$.apoderado.celular.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- SUB TITULO: Dirección -->
                <b-col
                  cols="12"
                  md="2"
                  class="mb-2 mt-4"
                >
                  <b-card-sub-title>
                    Dirección
                  </b-card-sub-title>
                </b-col>
                <b-col
                  cols="12"
                  md="10"
                  class="mb-1 mt-4"
                >
                  <b-card-sub-title >
                    <hr class="mt-75">
                  </b-card-sub-title>
                </b-col>

                <!-- Nombre CALLE -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Calle *"
                    label-for="nombre_calle"
                  >
                    <b-form-input
                      id="nombre_calle"
                      v-model="apoderado.nombre_calle"
                      placeholder="Av. General Borgoño"
                      :state="v$.apoderado.nombre_calle.$error === true
                      ? false
                      : null"
                      @blur.native="v$.apoderado.nombre_calle.$touch"
                    />
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.apoderado.nombre_calle.$error"
                      id="nombre_calleInfo"
                    >
                      <p
                        v-for="error of v$.apoderado.nombre_calle.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- NUMERO -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Numeración *"
                    label-for="numero"
                  >
                    <b-input-group
                      :class="v$.apoderado.numero.$error === false
                        ? ''
                        : 'is-invalid'"
                    >
                      <b-input-group-prepend is-text>
                        #
                      </b-input-group-prepend>
                      <b-form-input
                        id="numero"
                        v-model="apoderado.numero"
                        placeholder="1305"
                        :state="v$.apoderado.numero.$error === true
                        ? false
                        : null"
                        @keyup='apoderado.numero = formatSoloNumerosMaxLenght(apoderado.numero, 8)'
                        @blur.native="v$.apoderado.numero.$touch"
                      />
                    </b-input-group>
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.apoderado.numero.$error"
                      id="numeroInfo"
                    >
                      <p
                        v-for="error of v$.apoderado.numero.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- BLOCK -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Block"
                    label-for="block"
                  >
                    <b-form-input
                      id="block"
                      v-model="apoderado.block"
                      placeholder="A"
                      :state="v$.apoderado.block.$error === true
                        ? false
                        : null"
                      @blur.native="v$.apoderado.block.$touch"
                    />
                      <!-- @keyup='apoderado.block = formatSoloNumerosMaxLenght(apoderado.block, 8)' -->

                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.apoderado.block.$error"
                      id="blockInfo"
                    >
                      <p
                        v-for="error of v$.apoderado.block.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- DEPARTAMENTO -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Departamento"
                    label-for="departamento"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        #
                      </b-input-group-prepend>
                      <b-form-input
                        id="departamento"
                        v-model="apoderado.departamento"
                        placeholder="707"
                        @keyup='apoderado.departamento = formatSoloNumerosMaxLenght(apoderado.departamento, 8)'
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>

              </b-row>
            </b-col>
          </b-row>

          <col-linea
            style="margin-left: -20px !important; margin-right: -20px !important;"
          />

        </tab-content>

        <!-- Info Estudiante -->
        <tab-content
          title="Estudiante *"
          :before-change="validaEstudiante"
        >
          <b-row class="mb-2">

            <!-- TITULO: Info del estudiante -->
            <b-col
              cols="12"
              class="mb-2 text-center"
            >
              <h5 class="mb-0">
                Estudiante
              </h5>
              <small class="text-muted">
                Ingresa la información del estudiante.
              </small>
            </b-col>

            <!-- SUB TITULO: Información personal -->
            <b-col
              cols="12"
              md="2"
              class="mb-2 mt-2"
            >
              <b-card-sub-title>
                Información personal
              </b-card-sub-title>
            </b-col>
            <b-col
              cols="12"
              md="10"
              class="mb-1 mt-2"
            >
              <b-card-sub-title >
                <hr class="mt-75">
              </b-card-sub-title>
            </b-col>

            <b-col
              cols="6"
            >
              <b-row>

                <!-- TIPO IDENTIFICACION -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Tipo identificación *"
                    label-for="tipo_identificacion"
                  >
                    <v-select
                      v-model="estudiante.tipo_identificacion"
                      placeholder="Selecciona el tipo"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :reduce="option => option.tipo_identificacion"
                      :options="optionsTipoIdentificacion"
                      :class="v$.estudiante.tipo_identificacion.$error === true
                        ? 'border-danger rounded'
                        : ''"
                      @input="changeTipoIdentificacion()"
                    />
                      <!-- :disabled="optionsPaises.length === 0" -->
                    <div
                      v-if="v$.estudiante.tipo_identificacion.$error"
                      id="asistentesInfo"
                      class="text-danger text-left"
                      style="font-size: 0.857rem;"
                    >
                      <p v-for="error of v$.estudiante.tipo_identificacion.$errors" :key="error.$uid">
                        {{ error.$message }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- RUT -->
                <b-col
                  v-if="estudiante.tipo_identificacion === 1"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Rut *"
                    label-for="rut"
                  >
                    <b-form-input
                      id="rut"
                      v-model="estudiante.rut"
                      placeholder="11.111.111-1"
                      :state="v$.estudiante.rut.$error === true
                      ? false
                      : null"
                      @blur.native="v$.estudiante.rut.$touch"
                      @keyup="estudiante.rut = formatRut(estudiante.rut)"
                    />
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.estudiante.rut.$error"
                      id="rutInfo"
                      class="pb-0"
                    >
                      <p
                        v-for="error of v$.estudiante.rut.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- IPE -->
                <b-col
                  v-else-if="estudiante.tipo_identificacion === 2"
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="IPE *"
                    label-for="ipe"
                  >
                    <b-form-input
                      id="ipe"
                      v-model="estudiante.ipe"
                      placeholder="01122000"
                      :state="v$.estudiante.ipe.$error === true
                      ? false
                      : null"
                      @blur.native="v$.estudiante.ipe.$touch"
                      @keyup="estudiante.ipe = formatRut(estudiante.ipe)"
                    />
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.estudiante.ipe.$error"
                      id="rutInfo"
                      class="pb-0"
                    >
                      <p
                        v-for="error of v$.estudiante.ipe.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- NOMBRE -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Primer nombre *"
                    label-for="nombre"
                  >
                    <b-form-input
                      id="nombre"
                      v-model="estudiante.nombre"
                      placeholder="Paz Isidora"
                      :state="v$.estudiante.nombre.$error === true
                      ? false
                      : null"
                      @blur.native="v$.estudiante.nombre.$touch"
                    />
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.estudiante.nombre.$error"
                      id="nombreInfo"
                    >
                      <p
                        v-for="error of v$.estudiante.nombre.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- SEGUNDO NOMBRE -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Segundo nombre"
                    label-for="segundo_nombre"
                  >
                    <b-form-input
                      id="segundo_nombre"
                      v-model="estudiante.segundo_nombre"
                      placeholder="Paz Isidora"
                      :state="v$.estudiante.segundo_nombre.$error === true
                      ? false
                      : null"
                      @blur.native="v$.estudiante.segundo_nombre.$touch"
                    />
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.estudiante.segundo_nombre.$error"
                      id="segundo_nombreInfo"
                    >
                      <p
                        v-for="error of v$.estudiante.segundo_nombre.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- PRIMER APELLIDO -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Primer apellido *"
                    label-for="primer_apellido"
                  >
                    <b-form-input
                      id="primer_apellido"
                      v-model="estudiante.primer_apellido"
                      placeholder="Álvarez"
                      :state="v$.estudiante.primer_apellido.$error === true
                      ? false
                      : null"
                      @blur.native="v$.estudiante.primer_apellido.$touch"
                    />
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.estudiante.primer_apellido.$error"
                      id="primer_apellidoInfo"
                    >
                      <p
                        v-for="error of v$.estudiante.primer_apellido.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- SEGUNDO APELLIDO -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Segundo apellido *"
                    label-for="segundo_apellido"
                  >
                    <b-form-input
                      id="segundo_apellido"
                      v-model="estudiante.segundo_apellido"
                      placeholder="Rivera"
                      :state="v$.estudiante.segundo_apellido.$error === true
                      ? false
                      : null"
                      @blur.native="v$.estudiante.segundo_apellido.$touch"
                    />
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.estudiante.segundo_apellido.$error"
                      id="segundo_apellidoInfo"
                    >
                      <p
                        v-for="error of v$.estudiante.segundo_apellido.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- FECHA DE NACIMIENTO -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Fecha de nacimiento *"
                    label-for="fecha_nacimiento"
                  >
                    <flat-pickr
                      v-model="estudiante.fecha_nacimiento"
                      class="form-control"
                      :config="config.locale"
                      placeholder="Selecciona una fecha"
                      name="date"
                      :class="v$.estudiante.fecha_nacimiento.$error === true
                        ? 'form-control border-danger rounded'
                        : 'form-control'"
                    />
                    <!-- Validaciones -->
                    <div
                      v-if="v$.estudiante.fecha_nacimiento.$error"
                      id="asistentesInfo"
                      class="text-danger text-left"
                      style="font-size: 0.857rem;"
                    >
                      <p v-for="error of v$.estudiante.fecha_nacimiento.$errors" :key="error.$uid">
                        {{ error.$message }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- GENERO -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Genero *"
                    label-for="genero"
                  >
                    <v-select
                      v-model="estudiante.genero"
                      placeholder="Selecciona un genero"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :reduce="option => option.genero"
                      :options="optionsGeneros"
                      :class="v$.estudiante.genero.$error === true
                        ? 'border-danger rounded'
                        : ''"
                    />
                      <!-- :disabled="optionsGeneros.length === 0" -->
                    <div
                      v-if="v$.estudiante.genero.$error"
                      id="asistentesInfo"
                      class="text-danger text-left"
                      style="font-size: 0.857rem;"
                    >
                      <p v-for="error of v$.estudiante.genero.$errors" :key="error.$uid">
                        {{ error.$message }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>

              </b-row>
            </b-col>

            <b-col
              cols="6"
            >
              <b-row>

                <!-- CORREO -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Correo *"
                    label-for="correo"
                  >
                    <b-input-group
                      :class="v$.estudiante.correo.$error === false
                        ? ''
                        : 'is-invalid'"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MailIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="correo"
                        v-model="estudiante.correo"
                        placeholder="paz.alv@gmail.com"
                        :class="v$.estudiante.correo.$error === false
                          ? ''
                          : 'border-danger rounded-right'"
                        @blur.native="v$.estudiante.correo.$touch"
                        :state="v$.estudiante.correo.$error === true
                        ? false
                        : null"
                      />
                    </b-input-group>
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.estudiante.correo.$error"
                      id="correoInfo"
                    >
                      <p
                        v-for="error of v$.estudiante.correo.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- CELULAR -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Celular *"
                    label-for="celular"
                  >
                    <b-input-group
                      :class="v$.estudiante.celular.$error === false ? '' : 'is-invalid'"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="SmartphoneIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="celular"
                        v-model="estudiante.celular"
                        placeholder="56978717595"
                        :state="v$.estudiante.celular.$error === true
                        ? false
                        : null"
                        @keyup='estudiante.celular = formatSoloNumerosMaxLenght(estudiante.celular, 11)'
                        @blur.native="v$.estudiante.celular.$touch"
                      />
                    </b-input-group>
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.estudiante.celular.$error"
                      id="celularInfo"
                    >
                      <p
                        v-for="error of v$.estudiante.celular.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- SUB TITULO: Direcciones -->
                <b-col
                  cols="12"
                  md="2"
                  class="mb-2 mt-4"
                >
                  <b-card-sub-title>
                    Dirección
                  </b-card-sub-title>
                </b-col>
                <b-col
                  cols="12"
                  md="10"
                  class="mb-1 mt-4"
                >
                  <b-card-sub-title >
                    <hr class="mt-75">
                  </b-card-sub-title>
                </b-col>

                <!-- COMUNA -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Comuna *"
                    label-for="id_comuna"
                  >
                    <v-select
                      v-model="estudiante.id_comuna"
                      placeholder="Selecciona una comuna..."
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="nombre"
                      :reduce="option => option.id_comuna"
                      :options="optionsComunas"
                      :class="v$.estudiante.id_comuna.$error === true
                        ? 'border-danger rounded'
                        : ''"
                      :disabled="optionsComunas.length === 0"
                    />
                    <div
                      v-if="v$.estudiante.id_comuna.$error"
                      id="asistentesInfo"
                      class="text-danger text-left"
                      style="font-size: 0.857rem;"
                    >
                      <p v-for="error of v$.estudiante.id_comuna.$errors" :key="error.$uid">
                        {{ error.$message }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- CALLE -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Calle *"
                    label-for="nombre_calle"
                  >
                    <b-form-input
                      id="nombre_calle"
                      v-model="estudiante.nombre_calle"
                      placeholder="Av. General Borgoño"
                      :state="v$.estudiante.nombre_calle.$error === true
                      ? false
                      : null"
                      @blur.native="v$.estudiante.nombre_calle.$touch"
                    />
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.estudiante.nombre_calle.$error"
                      id="nombre_calleInfo"
                    >
                      <p
                        v-for="error of v$.estudiante.nombre_calle.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- NUMERO -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Numeración *"
                    label-for="numero"
                  >
                    <b-input-group
                      :class="v$.estudiante.numero.$error === false
                        ? ''
                        : 'is-invalid'"
                    >
                      <b-input-group-prepend is-text>
                        #
                      </b-input-group-prepend>
                      <b-form-input
                        id="numero"
                        v-model="estudiante.numero"
                        placeholder="1305"
                        :state="v$.estudiante.numero.$error === true
                        ? false
                        : null"
                        @keyup='estudiante.numero = formatSoloNumerosMaxLenght(estudiante.numero, 8)'
                        @blur.native="v$.estudiante.numero.$touch"
                      />
                    </b-input-group>
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.estudiante.numero.$error"
                      id="numeroInfo"
                    >
                      <p
                        v-for="error of v$.estudiante.numero.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- BLOCK -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Block"
                    label-for="block"
                  >
                    <b-form-input
                      id="block"
                      v-model="estudiante.block"
                      placeholder="A"
                      :state="v$.estudiante.block.$error === true
                        ? false
                        : null"
                      @blur.native="v$.estudiante.block.$touch"
                    />
                      <!-- @keyup='estudiante.block = formatSoloNumerosMaxLenght(estudiante.block, 8)' -->

                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.estudiante.block.$error"
                      id="blockInfo"
                    >
                      <p
                        v-for="error of v$.estudiante.block.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- DEPARTAMENTO -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Departamento"
                    label-for="departamento"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        #
                      </b-input-group-prepend>
                      <b-form-input
                        id="departamento"
                        v-model="estudiante.departamento"
                        placeholder="707"
                        @keyup='estudiante.departamento = formatSoloNumerosMaxLenght(estudiante.departamento, 8)'
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>

              </b-row>

            </b-col>


            <!-- SUB TITULO: Información escolar -->
            <b-col
              cols="12"
              md="2"
              class="mb-2 mt-3"
            >
              <b-card-sub-title>
                Información escolar
              </b-card-sub-title>
            </b-col>
            <b-col
              cols="12"
              md="10"
              class="mb-1 mt-3"
            >
              <b-card-sub-title >
                <hr class="mt-75">
              </b-card-sub-title>
            </b-col>

            <!-- CURSO -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Curso *"
                label-for="id_cursos"
              >
                <v-select
                  v-model="estudiante.id_cursos"
                  placeholder="Selecciona el curso..."
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :reduce="option => option.id_cursos"
                  :options="optionsCursos"
                  :class="v$.estudiante.id_cursos.$error === true
                    ? 'border-danger rounded'
                    : ''"
                  @input="changeTipoIdentificacion()"
                />
                  <!-- :disabled="optionsPaises.length === 0" -->
                <div
                  v-if="v$.estudiante.id_cursos.$error"
                  id="asistentesInfo"
                  class="text-danger text-left"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.estudiante.id_cursos.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </b-form-group>
            </b-col>

            <!-- FECHA INGRESO -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Fecha Ingreso *"
                label-for="fecha_ingreso"
              >
                <b-form-datepicker
                  id="fecha_ingreso"
                  v-model="estudiante.fecha_ingreso"
                  placeholder="Abrir calendario"
                  hide-header
                  :date-format-options="{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    weekday: 'short'
                  }"
                  start-weekday="1"
                  locale="es-CL"
                  label-help=""
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.estudiante.fecha_ingreso.$error"
                  id="fecha_ingresoInfo"
                >
                  <p
                    v-for="error of v$.estudiante.fecha_ingreso.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- PROCEDENCIA -->
            <!-- <b-col
              cols="12"
              md="12"
              class="mb-2"
            >
              <b-form-group
                label="Procedencia *"
                label-for="procedencia"
              >
                <b-form-input
                  id="procedencia"
                  v-model="estudiante.procedencia"
                  placeholder="Ingresa la procedenia"
                />
              </b-form-group>
            </b-col> -->

            <!-- BENEFICIO -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Beneficio Chile Solidario o Puente"
                label-for="beneficio"
                class="text-center"
              >
                <!-- label-cols-md="6" -->
                <b-form-checkbox
                  checked="true"
                  v-model="estudiante.beneficio"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!-- AUTORIZACION -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Autorización para almorzar afuera"
                label-for="autorizacion"
                class="text-center"
              >
                <!-- label-cols-md="6" -->
                <b-form-checkbox
                  checked="true"
                  v-model="estudiante.autorizacion"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!-- PRIORITARIO -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Estudiante prioritario"
                label-for="prioritario"
                class="text-center"
              >
                <!-- label-cols-md="6" -->
                <b-form-checkbox
                  checked="true"
                  v-model="estudiante.prioritario"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!-- JUNAEB -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Junaeb"
                label-for="junaeb"
                class="text-center"
              >
                <!-- label-cols-md="6" -->
                <b-form-checkbox
                  checked="true"
                  v-model="estudiante.junaeb"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!-- RELIGION -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Se autoriza a cursar religión"
                label-for="religion"
                class="text-center"
              >
                <!-- label-cols-md="6" -->
                <b-form-checkbox
                  checked="true"
                  v-model="estudiante.religion"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!-- PIE -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Programa de integración escolar (PIE)"
                label-for="pie"
                class="text-center"
              >
                <!-- label-cols-md="6" -->
                <b-form-checkbox
                  checked="true"
                  v-model="estudiante.pie"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!-- TRANSPORTE -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Transporte escolar"
                label-for="transporte"
                class="text-center"
              >
                <!-- label-cols-md="6" -->
                <b-form-checkbox
                  checked="true"
                  v-model="estudiante.transporte"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!-- REPITENCIA -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Repitencia"
                label-for="repitencia"
                class="text-center"
              >
                <!-- label-cols-md="6" -->
                <b-form-checkbox
                  checked="true"
                  v-model="estudiante.repitencia"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!-- APRENDIZAJE -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Problema de aprendizaje"
                label-for="aprendizaje"
                class="text-center"
              >
                <!-- label-cols-md="6" -->
                <b-form-checkbox
                  checked="true"
                  v-model="estudiante.aprendizaje"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>

          </b-row>

          <col-linea
            style="margin-left: -20px !important; margin-right: -20px !important;"
          />

        </tab-content>

        <!-- Nucleo Familiar -->
        <tab-content
          title="Núcleo Familiar"
          :before-change="validaNucleo"
        >
          <b-row>
            <!-- TÍTULO: Núcleo -->
            <b-col
              cols="12"
              class="mb-2 text-center"
            >
              <h5 class="mb-0">
                Núcleo familiar
              </h5>
              <small class="text-muted">
                Ingresa la información del núcleo familiar del estudiante.
              </small>
            </b-col>
            <b-col cols="6">

              <!-- INTEGRANTES -->
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  label="Integrantes *"
                  label-for="integrantes"
                >
                  <b-form-input
                    id="integrantes"
                    v-model="nucleo.integrantes"
                    placeholder="Ingresa el número de integrantes"
                    :state="v$.nucleo.integrantes.$error === true
                    ? false
                    : null"
                    @blur.native="v$.nucleo.integrantes.$touch"
                    @keyup="nucleo.integrantes = formatSoloNumeros(nucleo.integrantes)"
                  />
                  <!-- Validaciones -->
                  <b-form-invalid-feedback
                    v-if="v$.nucleo.integrantes.$error"
                    id="integrantesInfo"
                    class="pb-0"
                  >
                    <p
                      v-for="error of v$.nucleo.integrantes.$errors"
                      :key="error.$uid"
                    >
                      {{ error.$message }}
                    </p>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <!-- CAMAS -->
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  label="Camas *"
                  label-for="camas"
                >
                  <b-form-input
                    id="camas"
                    v-model="nucleo.camas"
                    placeholder="Ingresa el número de camas"
                    :state="v$.nucleo.camas.$error === true
                    ? false
                    : null"
                    @blur.native="v$.nucleo.camas.$touch"
                    @keyup="nucleo.camas = formatSoloNumeros(nucleo.camas)"
                  />
                  <!-- Validaciones -->
                  <b-form-invalid-feedback
                    v-if="v$.nucleo.camas.$error"
                    id="integrantesInfo"
                    class="pb-0"
                  >
                    <p
                      v-for="error of v$.nucleo.camas.$errors"
                      :key="error.$uid"
                    >
                      {{ error.$message }}
                    </p>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <!-- HACINAMIENTO -->
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  label="Hacinamiento *"
                  label-for="hacinamiento"
                >
                  <b-form-input
                    id="hacinamiento"
                    v-model="nucleo.hacinamiento"
                    placeholder="Ingresa el hacinamiento"
                    :state="v$.nucleo.hacinamiento.$error === true
                    ? false
                    : null"
                    @blur.native="v$.nucleo.hacinamiento.$touch"
                    @keyup="nucleo.hacinamiento = formatSoloNumeros(nucleo.hacinamiento)"
                  />
                  <!-- Validaciones -->
                  <b-form-invalid-feedback
                    v-if="v$.nucleo.hacinamiento.$error"
                    id="integrantesInfo"
                    class="pb-0"
                  >
                    <p
                      v-for="error of v$.nucleo.hacinamiento.$errors"
                      :key="error.$uid"
                    >
                      {{ error.$message }}
                    </p>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

            </b-col>
            <b-col cols="6">

              <b-row>

                <!-- NIVEL EDUCACIONAL PADRE -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Nivel de educación del padre *"
                    label-for="nivel_educacional_padre"
                  >
                    <v-select
                      v-model="nucleo.nivel_educacional_padre"
                      placeholder="Selecciona el tipo"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :reduce="option => option.nivel_educacional"
                      :options="optionsNivelEducacional"
                      :class="v$.nucleo.nivel_educacional_padre.$error === true
                        ? 'border-danger rounded'
                        : ''"
                    />
                      <!-- :disabled="optionsPaises.length === 0" -->
                    <div
                      v-if="v$.nucleo.nivel_educacional_padre.$error"
                      id="asistentesInfo"
                      class="text-danger text-left"
                      style="font-size: 0.857rem;"
                    >
                      <p v-for="error of v$.nucleo.nivel_educacional_padre.$errors" :key="error.$uid">
                        {{ error.$message }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- NIVEL EDUCACIONAL PADRE -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Nivel de educación de la madre *"
                    label-for="nivel_educacional_madre"
                  >
                    <v-select
                      v-model="nucleo.nivel_educacional_madre"
                      placeholder="Selecciona el tipo"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :reduce="option => option.nivel_educacional"
                      :options="optionsNivelEducacional"
                      :class="v$.nucleo.nivel_educacional_madre.$error === true
                        ? 'border-danger rounded'
                        : ''"
                    />
                      <!-- :disabled="optionsPaises.length === 0" -->
                    <div
                      v-if="v$.nucleo.nivel_educacional_madre.$error"
                      id="asistentesInfo"
                      class="text-danger text-left"
                      style="font-size: 0.857rem;"
                    >
                      <p v-for="error of v$.nucleo.nivel_educacional_madre.$errors" :key="error.$uid">
                        {{ error.$message }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- SUB TITULO: Entrega de certificados -->
                <b-col
                  cols="12"
                  md="4"
                  class="mb-2 mt-2"
                >
                  <b-card-sub-title>
                    Entrega de documentos
                  </b-card-sub-title>
                </b-col>
                <b-col
                  cols="12"
                  md="8"
                  class="mb-1 mt-2"
                >
                  <b-card-sub-title >
                    <hr class="mt-75">
                  </b-card-sub-title>
                </b-col>

                <!-- CERTIFICADO DE NACIMIENTO -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Certificado de nacimiento"
                    label-for="certificado_nacimiento"
                    class="text-center"
                  >
                    <!-- label-cols-md="6" -->
                    <b-form-checkbox
                      checked="true"
                      v-model="nucleo.certificado_nacimiento"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <!-- CERTIFICADO DE ESTUDIO -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Certificado de estudio"
                    label-for="certificado_estudio"
                    class="text-center"
                  >
                    <!-- label-cols-md="6" -->
                    <b-form-checkbox
                      checked="true"
                      v-model="nucleo.certificado_estudio"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <!-- CERTIFICADO DE PERSONALIDAD -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Certificado de personalidad"
                    label-for="certificado_personalidad"
                    class="text-center"
                  >
                    <!-- label-cols-md="6" -->
                    <b-form-checkbox
                      checked="true"
                      v-model="nucleo.certificado_personalidad"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

          </b-row>

          <col-linea
            style="margin-left: -20px !important; margin-right: -20px !important;"
          />

        </tab-content>

        <!-- Datos Clinicos  -->
        <tab-content
          title="Antecedentes de Salud"
          :before-change="validaClinico"
        >
          <b-row>
            <!-- TÍTULO: Datos Clínico -->
            <b-col
              cols="12"
              class="mb-2 text-center"
            >
              <h5 class="mb-0">
                Datos clínicos
              </h5>
              <small class="text-muted">
                Ingresa la información clínica del estudiante.
              </small>
            </b-col>


            <b-col cols="6">

              <!-- PREVISIÓN -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Previsión *"
                    label-for="prevision"
                  >
                    <v-select
                      v-model="clinico.prevision"
                      placeholder="Selecciona el tipo"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :reduce="option => option.prevision"
                      :options="optionsPrevision"
                      :class="v$.clinico.prevision.$error === true
                        ? 'border-danger rounded'
                        : ''"
                    />
                      <!-- @input="changePrevision()" -->
                      <!-- :disabled="optionsPaises.length === 0" -->
                    <div
                      v-if="v$.clinico.prevision.$error"
                      id="asistentesInfo"
                      class="text-danger text-left"
                      style="font-size: 0.857rem;"
                    >
                      <p v-for="error of v$.clinico.prevision.$errors" :key="error.$uid">
                        {{ error.$message }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- PATOLOGÍAS -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Patologías"
                    label-for="patologias"
                  >
                    <b-form-input
                      id="patologias"
                      v-model="clinico.patologias"
                      placeholder="Ingresa las patologias"
                      :state="v$.clinico.patologias.$error === true
                      ? false
                      : null"
                      @blur.native="v$.clinico.patologias.$touch"
                    />
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.clinico.patologias.$error"
                      id="integrantesInfo"
                      class="pb-0"
                    >
                      <p
                        v-for="error of v$.clinico.patologias.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- TRATAMIENTO MEDICO -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Tratamiento médico"
                    label-for="tratamiento_medico"
                  >
                    <b-form-input
                      id="tratamiento_medico"
                      v-model="clinico.tratamiento_medico"
                      placeholder="Ingresa el tratamiento medico"
                      :state="v$.clinico.tratamiento_medico.$error === true
                      ? false
                      : null"
                      @blur.native="v$.clinico.tratamiento_medico.$touch"
                    />
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.clinico.tratamiento_medico.$error"
                      id="integrantesInfo"
                      class="pb-0"
                    >
                      <p
                        v-for="error of v$.clinico.patologias.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

            </b-col>
            <b-col cols="6">
              <b-row>

                <!-- HORARIO MEDICACIÓN -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Horario medicación"
                    label-for="horario-medicacion"
                  >
                    <b-input-group>
                      <b-input-group-prepend
                        is-text
                      >
                        <feather-icon icon="ClockIcon" />
                      </b-input-group-prepend>
                      <cleave
                        id="horario_medicacion"
                        v-model='clinico.horario_medicacion'
                        class="form-control"
                        :raw="false"
                        :options="time"
                        placeholder="hh:mm"
                        :onValueChanged="formatHoraInicio(clinico.horario_medicacion)"
                      />

                    </b-input-group>
                  </b-form-group>
                </b-col>

                <!-- SUB TITULO: Actividad Física -->
                <b-col
                  cols="12"
                  md="4"
                  class="mb-2 mt-3"
                >
                  <b-card-sub-title>
                    Actividad física
                  </b-card-sub-title>
                </b-col>
                <b-col
                  cols="12"
                  md="8"
                  class="mb-1 mt-3"
                >
                  <b-card-sub-title >
                    <hr class="mt-75">
                  </b-card-sub-title>
                </b-col>

                <!-- ACTIVIDAD FÍSICA -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Autorización para desarrollar actividades físicas"
                    label-for="actividad_fisica"
                    class="text-center"
                  >
                    <!-- label-cols-md="6" -->
                    <b-form-checkbox
                      checked="true"
                      v-model="clinico.actividad_fisica"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <!-- DOCUMENTO ACTIVIDAD FÍSICA -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Presenta documento de actividad física"
                    label-for="documento_actividad_fisica"
                    class="text-center"
                  >
                    <!-- label-cols-md="6" -->
                    <b-form-checkbox
                      checked="true"
                      v-model="clinico.documento_actividad_fisica"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

              </b-row>
            </b-col>

          </b-row>

          <col-linea
            style="margin-left: -20px !important; margin-right: -20px !important;"
          />

        </tab-content>

      </form-wizard>
    </b-col>
  </b-row>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup,  BFormInput,  BInputGroup,  BInputGroupPrepend,
  BFormInvalidFeedback,  BCardSubTitle,  BFormDatepicker,  BFormSelect,
  BFormCheckbox, BInputGroupAppend,
} from 'bootstrap-vue'

import flatPickr from 'vue-flatpickr-component'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es.js';

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

// WIZARD
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

// CLEAVE
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import store from '@/store/index'


// VALIDACIONES //
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers, email } from '@vuelidate/validators'

// MIXINS
import { rut } from '@core/mixins/ui/rut'
import { formatos } from '@core/mixins/ui/formatos'

// COMPONENTES RECICLADOS
import colLinea from '../../components/Form/colLinea.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    vSelect,
    Cleave,
    BFormInvalidFeedback,
    BCardSubTitle,
    BFormDatepicker,
    BFormSelect,
    BFormCheckbox,
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,

    // COMPONENTES RECICLADOS
    colLinea,
  },
  directives: {
    Ripple,
  },
  mixins: [rut, formatos],
  data() {
    return {
      required,
      config: {
        time: null,
        wrap: true,
        noCalendar: false,
        locale: {
          locale: SpanishLocale,
        },
        showMonths: 1, // sirve para un desde hasta, muestra más calendarios
      },
      email,
      time: {
        time: true,
        timePattern: ['h', 'm'],
      },

      id_establecimiento_user: null,
      id_persona_apoderado: null, // *
      id_persona_rol_apoderado: null, // *
      id_matricula: null,

      apoderado: {
        rut: '',
        nombre: '',
        segundo_nombre: '',
        primer_apellido: '',
        segundo_apellido: '',
        correo: '',
        celular: '',
        genero: null,
        fecha_nacimiento: '',
        nombre_calle: '',
        numero: '',
      },
      tipoIdentificacionEstudiante: 1,
      estudiante: {
        rut: '',
        nombre: '',
        segundo_nombre: '',
        primer_apellido: '',
        segundo_apellido: '',
        genero: null,
        fecha_nacimiento: '',

        correo: '', // *
        celular: '', // *
        id_comuna: null,
        nombre_calle: '', // * av
        numero: null, // * 1


        tipo_identificacion: 1,
        fecha_ingreso: this.formatoFechaHoy(),
        id_pais: 45,
        id_cursos: null, // null

        procedencia: 27, // * estudiante nuevo con matrícula definitiva

        repitencia: false,
        prioritario: false,
        beneficio: false,
        pie: false,
        religion: false,
        junaeb: false,
        autorizacion: false,
        aprendizaje: false,
        transporte: false,
      },
      nucleo: {
        vive: null,
        renta: null,
        integrantes: null,
        camas: null,
        hacinamiento: null,
        certificado_nacimiento: false,
        certificado_estudio: false,
        certificado_personalidad: false,

        nivel_educacional_padre: null,
        nivel_educacional_madre: null,
        id_persona_rol_padre: null,
        id_persona_rol_madre: null,
      },
      clinico: {
        prevision: 'Fonasa',
        actividad_fisica: false,
        documento_actividad_fisica: false,
        patologias: '',
        tratamiendo_medico: '',
        horario_medicacion: '',
        id_persona_rol_emergencia: null,
      },

      // options
      optionsPaises: [],
      optionsComunas: [],
      optionsCursos: [],
      optionsTipoIdentificacion: [
        {
          tipo_identificacion: 1,
          title: 'RUT',
        },
        {
          tipo_identificacion: 2,
          title: 'IPE',
        }
      ],
      optionsGeneros: [
        {
          genero: 1,
          title: 'Masculino',
        },
        {
          genero: 2,
          title: 'Femenino',
        },
        {
          genero: 3,
          title: 'Otro',
        },
      ],
      optionsPrevision: [
        {
          prevision: 'Fonasa',
          title: 'Fonasa',
        },
        {
          prevision: 'Isapre',
          title: 'Isapre',
        }
      ],

      optionsNivelEducacional: [
        {
          nivel_educacional: 1,
          title: 'Básica',
        },
        {
          nivel_educacional: 2,
          title: 'Básica - Incompleta',
        },
        {
          nivel_educacional: 3,
          title: 'Media',
        },
        {
          nivel_educacional: 4,
          title: 'Media - Incompleta',
        },
        {
          nivel_educacional: 5,
          title: 'Técnico Nivel Superior',
        },
        {
          nivel_educacional: 6,
          title: 'Técnico Nivel Superior - Incompleta',
        },
        {
          nivel_educacional: 7,
          title: 'Universitaria',
        },
        {
          nivel_educacional: 8,
          title: 'Universitaria - Incompleta',
        },
      ],
    }
  },
  watch:{
    user (val){
      this.id_establecimiento_user = val.id_establecimiento
    },
    matricula(matricula) {
      this.setFormApoderado(matricula.id_persona_rol_apoderado)
    },
  },
  computed: {
    ...mapGetters({
      // getPersona: 'personas/getPersona',
      user: 'auth/user',
      getPaises: 'ceds/getPaises',
      getComunas: 'ceds/getComunas',
      getCursos: 'cursos/getCursos',
      getMatricula: 'matriculas/getMatricula',
    }),
    ...mapState('matriculas', ['matricula']),
  },
  validations() {
    let apoderado = new Object()
    let estudiante = new Object()

    let rut_estudiante = new Object()
    let ipe_estudiante = new Object()

    if (this.estudiante.tipo_identificacion === 1) {
      rut_estudiante = {
        $autoDirty: true,
        required: helpers.withMessage('El campo es requerido.', required),
        maxLength: helpers.withMessage('Debe tener máximo 13 caracteres.', maxLength(13)),
      }
    } else if (this.estudiante.tipo_identificacion === 2) {
      ipe_estudiante = {
        $autoDirty: true,
        required: helpers.withMessage('El campo es requerido.', required),
        maxLength: helpers.withMessage('Debe tener máximo 8 caracteres.', maxLength(8)),
      }
    }

    return {
      apoderado: {
        rut: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 13 caracteres.', maxLength(13)),
        },
        nombre: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 150 caracteres.', maxLength(150)),
        },
        segundo_nombre: {
          $autoDirty: true,
          // required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 150 caracteres.', maxLength(150)),
        },
        primer_apellido: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        segundo_apellido: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        genero: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        fecha_nacimiento: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },

        nombre_calle: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        numero: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        block: {
          $autoDirty: true,
          maxLength: helpers.withMessage('Debe tener máximo 10 caracteres.', maxLength(10)),
        },
        correo: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),

          email: helpers.withMessage('Debe ser un correo valido.', email),
        },
        celular: {
          $autoDirty: true,
          maxLength: helpers.withMessage('Debe tener máximo 11 caracteres.', maxLength(11)),
          required: helpers.withMessage('El campo es requerido.', required),
        },
      },
      estudiante: {
        rut: rut_estudiante,
        ipe: ipe_estudiante,
        nombre: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 150 caracteres.', maxLength(150)),
        },
        segundo_nombre: {
          $autoDirty: true,
          maxLength: helpers.withMessage('Debe tener máximo 150 caracteres.', maxLength(150)),
        },
        primer_apellido: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        segundo_apellido: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        genero: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        fecha_nacimiento: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },

        nombre_calle: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        numero: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },


        tipo_identificacion: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        fecha_ingreso: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        id_comuna: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        // id_pais: {
        //   $autoDirty: true,
        //   required: helpers.withMessage('El campo es requerido.', required),
        // },

        block: {
          $autoDirty: true,
          maxLength: helpers.withMessage('Debe tener máximo 10 caracteres.', maxLength(10)),
        },

        correo: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
          email: helpers.withMessage('Debe ser un correo valido.', email),
        },
        celular: {
          $autoDirty: true,
          maxLength: helpers.withMessage('Debe tener máximo 11 caracteres.', maxLength(11)),
          required: helpers.withMessage('El campo es requerido.', required),
        },

        id_cursos: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
      },
      nucleo: {
        integrantes: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          // maxLength: helpers.withMessage('Debe tener máximo 150 caracteres.', maxLength(150)),
        },
        camas: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          // maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        hacinamiento: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          // maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        nivel_educacional_padre: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          // maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        nivel_educacional_madre: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          // maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
      },
      clinico: {
        prevision: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 150 caracteres.', maxLength(150)),
        },
        patologias: {
          $autoDirty: true,
          maxLength: helpers.withMessage('Debe tener máximo 200 caracteres.', maxLength(200)),
        },
        tratamiento_medico: {
          $autoDirty: true,
          maxLength: helpers.withMessage('Debe tener máximo 250 caracteres.', maxLength(250)),
        },
      },
    }
  },
  mounted() {
    this.cargaUser()
    this.cargaPaises()
    this.cargaComunas()
  },
  methods: {
    ...mapActions({
      fetchPaises: 'ceds/fetchPaises',
      fetchComunas: 'ceds/fetchComunas',
      fetchCursosEstablecimiento: 'cursos/fetchCursosEstablecimiento',

      fetchPersona: 'personas/fetchPersona',
      createPersona: 'personas/addPersona',
      updatePersona: 'personas/updatePersona',

      fetchMatricula: 'matriculas/fetchMatricula',
      createMatricula: 'matriculas/addMatricula',
      updateMatricula: 'matriculas/updateMatricula',
      createMatriculaNucleo: 'matriculas/addMatriculaNucleo',
      createMatriculaClinico: 'matriculas/addMatriculaClinico',

      attempt: 'auth/attempt',
    }),
    setFormApoderado(id_persona_rol_apoderado) {
      console.log('id_persona_rol_apoderado :', id_persona_rol_apoderado)
    },
    cargaUser() {
      if (this.id_establecimiento_user === null) {
        this.attempt().then(() => {
          this.cargaCursos()
        })
      } else {
        this.cargaCursos()
      }
    },
    cargaPaises() {
      this.fetchPaises().then(() => {
        this.optionsPaises = []
        this.getPaises.forEach(pais => {

          this.optionsPaises.push({
            id_pais: pais.RefCountryId,
            nombre: pais.Description,
          })
        })
      })
    },
    cargaComunas() {
      this.fetchComunas().then(() => {
        this.optionsComunas = []
        this.getComunas.forEach(comuna => {
          this.optionsComunas.push({
            id_comuna: comuna.RefCountyId,
            nombre: comuna.Description,
          })
        })
      })
    },
    cargaCursos() {
      this.fetchCursosEstablecimiento(this.id_establecimiento_user).then(() => {
        this.optionsCursos = []
        this.getCursos.forEach(curso => {
          const nombre = curso.nombre+' '+ curso.letra
          this.optionsCursos.push({
            id_cursos: curso.id,
            title: nombre,
          })
        })
      })
    },
    // changePrevision() {
    // },
    formatHoraInicio(date) {
      // EJEMPLO: 13:30
      let horario_medicacion
      if (typeof date !== 'undefined') {
        const hora = date.split(':')[0]
        const minutos = date.split(':')[1]
        horario_medicacion = `${hora}:${minutos}`
        if (minutos === 'undefined') {
          horario_medicacion = hora
        }
        if (horario_medicacion === ':undefined') {
          horario_medicacion = '08:00'
        }
      }
      return horario_medicacion
    },

    changeTipoIdentificacion() {
      if (this.estudiante.tipo_identificacion === null) {
        this.estudiante.tipo_identificacion = 1
      }
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Estudiante matriculado!',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    validaApoderado() {

      return new Promise((resolve, reject) => {
        this.v$.apoderado.$touch()
        if (!this.v$.apoderado.$invalid) {
          if (this.id_persona_apoderado === null) { // CREAR
            const rut = this.divideRut(this.apoderado.rut) // mixin format
            const data = {
              rut: rut.rutSinDv,
              dv: rut.dv,
              nombre: this.apoderado.nombre,
              segundo_nombre: this.apoderado.segundo_nombre,
              primer_apellido: this.apoderado.primer_apellido,
              segundo_apellido: this.apoderado.segundo_apellido,
              celular: this.apoderado.celular,
              correo: this.apoderado.correo,
              genero: this.apoderado.genero,
              fecha_nacimiento: this.apoderado.fecha_nacimiento,

              nombre_calle: this.apoderado.nombre_calle,
              numero: this.apoderado.numero,
              block: this.apoderado.block,
              departamento: this.apoderado.departamento,

              id_rol: 11, // APODERADO
              id_establecimiento: this.id_establecimiento_user,
            }

            this.createPersona(data).then(() => {
              const statusPersonas = store.state.personas.status
              if (statusPersonas === 'success') {

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Apoderado guardado 👍',
                    icon: 'CheckIcon',
                    text: 'El apoderado fue creado con éxito!',
                    variant: 'success',
                  },
                },
                {
                  position: 'bottom-right',
                  timeout: 6000,
                })

                this.id_persona_apoderado = store.state.personas.id_persona
                this.id_persona_rol_apoderado = store.state.personas.id_persona_rol
                resolve(true)
              }
              else {
                this.$swal({
                  title: 'Error!',
                  text: store.state.personas.message,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                reject()
              }
            })
          } else {                                  // EDITAR
            // NOTA: Comparar datos con los de bd para ver si se edita o no.                                                       a para ver si tiene los mismos datos o hay que editar
            // this.fetchPersona(this.id_persona_apoderado).then(() => {
            // })

            const rut = this.divideRut(this.apoderado.rut) // mixin format
            const data = {
              id: this.id_persona_apoderado,
              rut: rut.rutSinDv,
              dv: rut.dv,
              nombre: this.apoderado.nombre,
              segundo_nombre: this.apoderado.segundo_nombre,
              primer_apellido: this.apoderado.primer_apellido,
              segundo_apellido: this.apoderado.segundo_apellido,
              celular: this.apoderado.celular,
              correo: this.apoderado.correo,
              genero: this.apoderado.genero,
              fecha_nacimiento: this.apoderado.fecha_nacimiento,

              nombre_calle: this.apoderado.nombre_calle,
              numero: this.apoderado.numero,
              block: this.apoderado.block,
              departamento: this.apoderado.departamento,

              id_rol: 11, // APODERADO
              id_establecimiento: this.id_establecimiento_user,
            }

            this.updatePersona(data).then(() => {
              const statusPersonas = store.state.personas.status
              if (statusPersonas === 'success') {

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Apoderado guardado 👍',
                    icon: 'CheckIcon',
                    text: 'El apoderado fue editado con éxito!',
                    variant: 'success',
                  },
                },
                {
                  position: 'bottom-right',
                  timeout: 6000,
                })

                resolve(true)
              }
              else {
                this.$swal({
                  title: 'Error!',
                  text: store.state.personas.message,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                reject()
              }
            })
          }
        } else {
          reject()
          // resolve(true) // ELIMINAR
        }
      })
    },
    validaEstudiante() {
      return new Promise((resolve, reject) => {
        this.v$.estudiante.$touch()
        if (!this.v$.estudiante.$invalid) {

            const rut = this.divideRut(this.estudiante.rut) // mixin format
            const data = {
              rut: rut.rutSinDv,
              dv: rut.dv,
              nombre: this.estudiante.nombre,
              segundo_nombre: this.estudiante.segundo_nombre,
              primer_apellido: this.estudiante.primer_apellido,
              segundo_apellido: this.estudiante.segundo_apellido,
              celular: this.estudiante.celular,
              correo: this.estudiante.correo,
              nombre_calle: this.estudiante.nombre_calle,
              numero: this.estudiante.numero,
              id_comuna: this.estudiante.id_comuna,
              id_pais: 45,
              fecha_ingreso: this.estudiante.fecha_ingreso,
              tipo_identificacion: this.estudiante.tipo_identificacion,
              genero: this.estudiante.genero,
              fecha_nacimiento: this.estudiante.fecha_nacimiento,
              repitencia: this.estudiante.repitencia,
              prioritario: this.estudiante.prioritario,
              beneficio: this.estudiante.beneficio,
              pie: this.estudiante.pie,
              religion: this.estudiante.religion,
              junaeb: this.estudiante.junaeb,
              autorizacion: this.estudiante.autorizacion,
              aprendizaje: this.estudiante.aprendizaje,
              transporte: this.estudiante.transporte,
              procedencia: this.estudiante.procedencia,
              id_cursos: this.estudiante.id_cursos,
              id_persona_rol_apoderado: this.id_persona_rol_apoderado,
              id_establecimiento: this.id_establecimiento_user,
            }

            this.createMatricula(data).then(() => {
              const statusPersonas = store.state.matriculas.status
              if (statusPersonas === 'success') {

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Matrícula ingresada 👍',
                    icon: 'CheckIcon',
                    text: 'La matrícula fue creada con éxito!',
                    variant: 'success',
                  },
                },
                {
                  position: 'bottom-right',
                  timeout: 6000,
                })

                this.id_matricula = store.state.matriculas.id_matricula
                resolve(true)
              }
              else {
                this.$swal({
                  title: 'Error!',
                  text: store.state.matriculas.message,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                reject()
              }
            })


        } else{
          reject()
          // resolve(true) // ELIMINAR
        }
      })
    },
    validaNucleo() {
      return new Promise((resolve, reject) => {
        this.v$.nucleo.$touch()
        console.log('this.v$.nucleo.$touch() :', this.v$.nucleo.$touch())
        if (!this.v$.nucleo.$invalid) {

          const data = {
            vive: this.nucleo.vive,
            renta: this.nucleo.renta,
            integrantes: this.nucleo.integrantes,
            camas: this.nucleo.camas,
            hacinamiento: this.nucleo.hacinamiento,
            certificado_nacimiento: this.nucleo.certificado_nacimiento,
            certificado_estudio: this.nucleo.certificado_estudio,
            certificado_personalidad: this.nucleo.certificado_personalidad,
            nivel_educacional_padre: this.nucleo.nivel_educacional_padre,
            nivel_educacional_madre: this.nucleo.nivel_educacional_madre,
            id_persona_rol_padre: this.nucleo.id_persona_rol_padre,
            id_persona_rol_madre: this.nucleo.id_persona_rol_madre,
            id_matricula: this.id_matricula,
          }
          this.createMatriculaNucleo(data).then(() => {
            const statusPersonas = store.state.matriculas.status
            if (statusPersonas === 'success') {

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Núcleo familiar guardado 👍',
                  icon: 'CheckIcon',
                  text: 'El núcleo familiar fue creado con éxito!',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
                timeout: 6000,
              })

              resolve(true)
            }
            else {
              this.$swal({
                title: 'Error!',
                text: store.state.matriculas.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              reject()
            }
          })
        }
        // resolve(true) // ELIMINAR
      })
    },
    validaClinico() {
      return new Promise((resolve, reject) => {
        this.v$.clinico.$touch()

        if (!this.v$.clinico.$invalid) {
          const data = {
            prevision: this.clinico.prevision,
            actividad_fisica: this.clinico.actividad_fisica,
            documento_actividad_fisica: this.clinico.documento_actividad_fisica,
            patologias: this.clinico.patologias,
            tratamiento_medico: this.clinico.tratamiento_medico,
            horario_medicacion: this.clinico.horario_medicacion,
            id_persona_rol_emergencia: this.clinico.id_persona_rol_emergencia,
            id_matricula: this.id_matricula,
          }

          this.createMatriculaClinico(data).then(() => {
            const statusMatriculas = store.state.matriculas.status
            if (statusMatriculas === 'success') {

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Datos clínicos guardados 👍',
                  icon: 'CheckIcon',
                  text: 'Los datos clínicos han sido creados con éxito!',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
                timeout: 6000,
              })

              resolve(true)
              this.$router.replace({
                name: 'matriculas',
              })
            }
            else {
              this.$swal({
                title: 'Error!',
                text: store.state.matriculas.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              reject()
            }
          })
        }
        // resolve(true) // ELIMINAR
      })
    },

    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay()
      const day = date.getDate()
      // Return `true` if the date should be disabled
      // return weekday === 0 || weekday === 6 || day === 1
      return weekday === 0 || weekday === 6
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
